
import { useRef } from "react";
import { VideoPojo } from "../../services/ManageReview/ReviewPojo/VideoPojo";
const VideoList = (props: { videoDetails: VideoPojo, key: number }) => {
    const videoList = props.videoDetails;
    const videoRef = useRef<HTMLVideoElement>(null);
    return (
        <>
            <div className="col-md-4 mb-2" key={videoList.videoId}>
                <video className="newBusinessSignUpVideo" ref={videoRef} controls poster={videoList.thumbnail}> 
                    <source src={videoList.videoUrl} /> 
                </video>
            </div>
        </>
    )

}

export default VideoList;