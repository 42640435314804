import CIcon from "@coreui/icons-react";
import { ImagePojo } from "../../services/ManageReview/ReviewPojo/ImagePojo";
import { cilDataTransferDown } from "@coreui/icons";

const ImageList = (props: { image: ImagePojo, key: number }) => {
    const image = props.image;

    return (
        <>
            <div className="col-md-4 mb-2" key={image.imageId}>
                <div className="" >
                    <a href={image.filePath} title="Click to download">
                        <img src={image.filePath} className="card-img-top" alt="..." />
                        <div className="download-icon">
                            <CIcon icon={cilDataTransferDown} customClassName="nav-icon"/>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )

}

export default ImageList;