import { API_GET_ALL_REVIEW, API_DELETE_REVIEW } from './ReviewConstant';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import Validator from "../Validator/Validator";
import ReviewResultPojo from './ReviewPojo/ReviewResultPojo';

export class ReviewService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(businessId : number|null): Promise<ReviewResultPojo>{
        try{
            let api = businessId ? API_GET_ALL_REVIEW+'/'+businessId : API_GET_ALL_REVIEW;
            const httpResult =  await this.httpService.get(api, {}); 
            return { isSuccess : httpResult.data.isSuccess, reviews : httpResult.data.response.reviews };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async delete (reviewId : number, status: number) : Promise<boolean>{
            try {
                this.validator.isNumberGreaterThan('reviewId', reviewId);
                this.validator.isNumberGreaterThan('status', status);
                const httpResult = await this.httpService.delete(API_DELETE_REVIEW, { reviewId : reviewId, status : status });
                return httpResult.data.response;
            } catch (e){
                this.logger.error(e);
                throw e;
            }
        }

   
}