import { CCol } from "@coreui/react";
import { Language } from "../../language/English";
import CIcon from "@coreui/icons-react"
import { cilStar, cilUser } from "@coreui/icons";
import { ReviewPojo } from "../../services/ManageReview/ReviewPojo/ReviewPojo";
import { ReviewService } from "../../services/ManageReview/ReviewService";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import { REVIEW_DELETE_TYPE, REVIEW_STATUS_DELETED } from "../../services/ManageReview/ReviewConstant";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import Loader from "../../components/Layout/Loader";
import Modal from "../../components/Layout/Modal";
import ImageList from "./ImageList";
import VideoList from "./VideoList";

const ReviewList = (props: { reviews: ReviewPojo, key: number, count: number }) => {
    const reviews = props.reviews;
    const business = reviews.business;
    const userDetails = reviews.user_details;
    const images = reviews.images;
    const videos = reviews.video;

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [deleteConfirmationBoxShow, setDeleteConfirmationBoxShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [viewVideo, setViewVideo] = useState(false);
    const [getVideo, setVideo] = useState(false);
    const [reviewStatus, setReviewStatus] = useState<number>();

    
    const deleteHandleConfirmation = (status: number) => {
        setDeleteConfirmationBoxShow(!deleteConfirmationBoxShow);
    }

    const deleteReview = (reviewId: number) =>
    {
        changeStatusClick(reviewId, REVIEW_STATUS_DELETED, REVIEW_DELETE_TYPE);
    }
    const notification = new Notification();
    const logger = new Logger();
    const changeStatusClick = async(reviewId: number, status: number, type : string) =>
    {
        setShowLoader(true);
        try{
            const reviewService = new ReviewService();
            let response: any;
            if (type === REVIEW_DELETE_TYPE) {
                response = await reviewService.delete(reviewId, status);
                setReviewStatus(status);
            }
            if(response){
                if (type === REVIEW_DELETE_TYPE) {
                    notification.success(Language.DELETED);
                } 
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const handleDisplay = () => {
        if (images && images.length > 0) {
            setViewImage(true);
        }

        if (videos && videos.length > 0) {
            setViewVideo(true);
        }
    }

    useEffect(() => {
        handleDisplay();
    });

    return (
        <>
            { showLoader ? <Loader /> : null }
            {
                reviewStatus === REVIEW_STATUS_DELETED ? null :
                <CCol md={4} className="mt-2">
                    <div className={`card`} id={`imageId-1`}>
                        <div className="topDateTimeSection">
                            {
                                <>
                                    <div className="requestOnSection">
                                        <div className="close-button" onClick={() => deleteHandleConfirmation(REVIEW_STATUS_DELETED)}>
                                            <span>X</span>
                                            {
                                                deleteConfirmationBoxShow ?
                                                    <ConfirmationBoxModal
                                                        title={reviews.title}
                                                        message={Language.REVIEW_DELETE_CONFIRMATION}
                                                        buttonOneText={Language.YES}
                                                        buttonTwoText={Language.CLOSE}
                                                        buttonOneCallable={deleteReview}
                                                        buttonTwoCallable={deleteHandleConfirmation}
                                                        buttonOneCallBackFunctionParams={reviews.reviewId}
                                                        buttonTwoCallBackFunctionParams={null} />
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                reviews.reviewDate && (
                                    <>
                                        <div className="finishOnSection">
                                            <p className="label">
                                                {Language.REVIEW_DATE}
                                            </p>
                                            <p className="date">
                                                {format(reviews.reviewDate, "dd MMM yyyy, hh:mm a")}
                                            </p>
                                        </div>
                                    </>
                                )
                            }
                            
                        </div>
                        
                        <CCol md={12} className="card-images col-12 business-detail-box">
                            <p>
                                <b><a href={business.businessUrl} target="_blank" rel="noreferrer">{business.name}</a></b>
                                <br></br>
                                <a href={`tel:${business.phoneCountryCode}${business.phoneNumber}`}>{`+${business.phoneCountryCode}${business.phoneNumber}`}</a>
                                <br></br>
                                {business.addressLine1}, {business.addressLine2}, {business.addressLine3}
                            </p>
                        </CCol>

                        <CCol md={12} className="card-images col-12 business-detail-box">
                            <hr />
                            <p className="text-center"><b>{Language.REVIEW_DETAILS}</b></p>
                            <div className="ratingDetails">
                                <p className="text-info"> <b><CIcon icon={cilUser} />&nbsp;{userDetails.name}</b></p>
                                <p>{reviews.rating} <CIcon icon={cilStar} className="star-icon" /> {Language.RATING}</p>
                                <p>{reviews.serviceRating} <CIcon icon={cilStar} className="star-icon" /> {Language.SERVICE_RATING}</p>
                                <p>{reviews.hygieneRating} <CIcon icon={cilStar} className="star-icon" /> {Language.HYGIENE_RATING}</p>
                                <p>{reviews.valueForMoneyRating} <CIcon icon={cilStar} className="star-icon" /> {Language.VALUE_FOR_MONEY_RATING}</p>
                            </div>
                            
                            <p> <b>{reviews.title}</b></p>
                            <p> {reviews.description}</p>
                        </CCol>

                        { 
                            reviews.reply && ( 
                                <CCol md={12} className="card-images col-12 business-detail-box">
                                    <hr />
                                    <p className="text-center"><b>{Language.REVIEW_REPLY}</b></p>
                                    <p> {reviews.reply}</p>
                                </CCol>
                            )
                        }

                        <CCol md={12} className="card-images col-12 business-detail-box">
                            {viewImage || viewVideo ? <hr /> : ''}
                            <p className="bookedServices">
                                <span>
                                {
                                    viewImage ?
                                        <>
                                            <span className="new-business-request-image" onClick={() => setVisible(!visible)}>{Language.VIEW_IMAGE}</span>
                                            {
                                                <>
                                                    <Modal title={reviews.title} visible={visible} className="new-business-sign-up-request-modal">
                                                        <div className="row">
                                                            {
                                                                images.length > 0 && images.map((image, index) => (
                                                                    <ImageList image={image} key={image.imageId} />
                                                                ))
                                                            }
                                                        </div>
                                                    </Modal>
                                                </>
                                            }
                                        </>
                                        : ''
                                }
                                </span>
                            </p>
                            <p className="bookedServices">
                                <span>
                                {
                                    viewVideo ?
                                        <>
                                            <span className="new-business-request-image" onClick={() => setVideo(!getVideo)}>{Language.VIEW_VIDEO}</span>
                                            {
                                                <>
                                                    <Modal title={reviews.title} visible={getVideo} className="new-business-sign-up-request-modal">
                                                        <div className="row">
                                                            {
                                                                videos.length > 0 && videos.map((videoDetails, index) => (
                                                                    <VideoList videoDetails={videoDetails} key={videoDetails.videoId} />
                                                                ))
                                                            }
                                                        </div>
                                                    </Modal>
                                                </>
                                            }
                                        </>
                                        : ''
                                }
                                </span>
                            </p>
                        </CCol>
                        
                    </div>
                </CCol>
            }
        </>
    )
}
export default ReviewList;