import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { ReviewService } from "../../services/ManageReview/ReviewService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import ReviewList from "./ReviewList";
import { CCol, CRow } from "@coreui/react";
import { ReviewPojo } from "../../services/ManageReview/ReviewPojo/ReviewPojo";
import { useParams } from "react-router-dom";

const BookingPayment = () => {
    const [manageReview, setManageReview] = useState<ReviewPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { businessId } = useParams();
    useEffect(() => {
        const notification = new Notification();
        const logger = new Logger();
        const fetchBookingPayments = async() => {
            try{
                const reviewService = new ReviewService();
                const response = await reviewService.getAll(businessId ? parseInt(businessId) : null);
                if(response.reviews){
                    setManageReview(response.reviews);
                } else {
                    notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
                }
                setShowLoader(false);
            } catch (e){
                notification.error(Language.SOMETHING_IS_WRONG);
                setShowLoader(false);
                logger.error(e);
            }
        }
        setShowLoader(true);
        fetchBookingPayments();
    }, [businessId]);
    return (
        <>
            { showLoader ? <Loader /> : null }
            <Layout>
                <div className="list bookingList">           
                    <CCol md={12}>
                        <CRow>
                        {
                            manageReview.length > 0 ? (
                                manageReview.map((manageReview, index) => (
                                    <ReviewList reviews={manageReview} key={manageReview.reviewId} count={index} />
                                ))
                            ) : (
                                <h2 className="text-center">No reviews available.</h2>
                            )
                        }
                        </CRow>
                    </CCol>
                </div>
            </Layout>
        </>
        
    )
}
export default BookingPayment;