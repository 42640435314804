import { CCol, CContainer, CRow, } from "@coreui/react";
import { Language } from "../../../language/English";
import OverallReviewForm from "./OverallReviewForm";
import { PAGE_REVIEWS } from "../../../PagesNames";
import { NavLink, useParams } from "react-router-dom";
const OverallReview = () => {
    const { businessId } = useParams();
    return (
        <CContainer className="mt-4">
            <CRow>
                <CCol md={12} className="mb-2">
                    <h3> {Language.OVERALL_REVIEW} </h3>
                    <NavLink to={`${PAGE_REVIEWS}/${businessId}`}>
                        {Language.MANAGE_REVIEW}
                    </NavLink>
                </CCol>
                <OverallReviewForm />
            </CRow>
        </CContainer>
    )
}
export default OverallReview;